import React, {useEffect, useState} from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Checkbox,
    Chip,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    IconButton,
    InputLabel,
    MenuItem,
    Paper,
    Popover,
    Select,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    TextField,
    Tooltip,
    Typography,
} from '@material-ui/core';
import {Icon} from '@vacasa/react-components-lib';
import './PromotionsList.scss';
import {CurrentUser, DateList, Promotion, UnitList} from "../../types";
import {
    useAddDateListMutation,
    useAddPromotionMutation,
    useAddUnitListMutation,
    useApprovePromotionMutation,
    useGetAllDateListsQuery,
    useGetAllPromotionsQuery,
    useGetAllUnitListsQuery,
    useGetPromotionOptionsQuery,
    useUpdatePromotionMutation,
} from "../../store";
import {format} from 'date-fns';
import {KeyboardDatePicker, MuiPickersUtilsProvider,} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {UiUtils} from "../../utils";

interface PromotionsListProps {
    currentUser: CurrentUser;
    openUnitListModal: (listId: number) => void;
    openDateListModal: (listId: number) => void;
    setRefetchPromotions: (fn: () => Promise<any>) => void;
}

type SortDirection = 'asc' | 'desc';
type FormMode = 'create' | 'edit' | 'copy';

interface PromotionFormData {
    // Basic Info
    name: string;
    description: string;
    active: boolean;
    priority: number;
    channel: string;

    // Channel Info
    strategy_type_id: number;
    promo_type_id: number;
    usage_type_id: number;
    channel_promo_name: string;
    channel_promo_id: number;
    promo_code: string;

    // Feature Flags
    stackable: boolean;
    merchandising: boolean;
    strikethrough: boolean;
    search_ranking: boolean;
    marketing_included: boolean;
    create_via_api: boolean;
    update_via_api: boolean;

    // Dates and Times
    active_date_start: string;
    active_date_end: string;

    // Lists
    inclusion_list_ids: number[];
    exclusion_list_ids: number[];

    // Discount Settings
    blackout_method: string;
    discount_value: number;
    discount_type: string;

    // Date Lists
    stay_date_list_id: number | null;
    blackout_date_list_id: number | null;
}

// Add helper function to get option names
const getOptionName = (id: number | null, options: any[], defaultValue: string = '-') => {
    if (!id || !options) return defaultValue;
    const option = options.find(opt => opt.id === id);
    return option ? option.name : defaultValue;
};

// Add helper for status icon and color
const getStatusInfo = (status: string) => {
    switch (status.toLowerCase()) {
        case 'approved':
            return { icon: <Icon.CheckCircle height={16} width={16} stroke="green" />, color: 'green' };
        case 'rejected':
            return { icon: <Icon.XCircle height={16} width={16} stroke="red" />, color: 'red' };
        case 'pending':
        default:
            return { icon: <Icon.Clock height={16} width={16} stroke="orange" />, color: 'orange' };
    }
};

// Add style for chevron animation
const chevronStyle = (isExpanded: boolean) => ({
    transform: isExpanded ? 'rotate(180deg)' : 'rotate(270deg)',
    transition: 'transform 300ms',
});

// Add helper function to filter options by channel
const getChannelOptions = (options: any[] | undefined, channel: string) => {
    if (!options) return [];
    return options.filter(option => option[channel]);
};

// Add new types for column configuration
interface ColumnConfig {
    id: keyof Promotion;
    label: string;
    sortable?: boolean;
    minWidth?: number;
}

// Create column groups
const columnGroups = {
    Basic: [
        { id: 'id', label: 'ID', sortable: true },
        { id: 'name', label: 'Name', sortable: true },
        { id: 'active', label: 'Status', sortable: true },
        { id: 'approved_status', label: 'Approval', sortable: true },
        { id: 'priority', label: 'Priority', sortable: true },
    ],
    Metadata: [
        { id: 'creator_email', label: 'Creator', sortable: true },
        { id: 'created_at', label: 'Created', sortable: true },
        { id: 'updater', label: 'Last Updated By', sortable: true },
        { id: 'updated_at', label: 'Last Updated', sortable: true },
    ],
    Channel: [
        { id: 'channel', label: 'Channel', sortable: true },
        { id: 'strategy_type_id', label: 'Strategy Type', sortable: true },
        { id: 'promo_type_id', label: 'Promotion Type', sortable: true },
        { id: 'usage_type_id', label: 'Usage Type', sortable: true },
        { id: 'promo_code', label: 'Promo Code', sortable: true },
        { id: 'blackout_method', label: 'Blackout Method', sortable: true },
    ],
    Features: [
        { id: 'stackable', label: 'Stackable', sortable: true },
        { id: 'merchandising', label: 'Merchandising', sortable: true },
        { id: 'strikethrough', label: 'Strikethrough', sortable: true },
        { id: 'search_ranking', label: 'Search Ranking', sortable: true },
        { id: 'marketing_included', label: 'Marketing', sortable: true },
        { id: 'create_via_api', label: 'Create via API', sortable: true },
        { id: 'update_via_api', label: 'Update via API', sortable: true },
    ],
    Dates: [
        { id: 'active_dates', label: 'Active Date Range', sortable: false },
        { id: 'active_date_start', label: 'Active From', sortable: true },
        { id: 'active_date_end', label: 'Active Until', sortable: true },
        { id: 'stay_date_list_id', label: 'Stay Date List', sortable: true },
        { id: 'nights', label: 'Nights', sortable: true },
        { id: 'stay_dates', label: 'Stay Date Range', sortable: false },
        { id: 'stay_date_start', label: 'Stay From', sortable: true },
        { id: 'stay_date_end', label: 'Stay Until', sortable: true },
        { id: 'days_of_week', label: 'Days', sortable: false },
        { id: 'blackout_date_list_id', label: 'Blackout Dates', sortable: true },
        { id: 'blackout_nights', label: 'Blackout Nights', sortable: true },
    ],
    Units: [
        { id: 'inclusion_list_ids', label: 'Included Lists', sortable: false },
        { id: 'inclusion_count', label: 'Included Units', sortable: true },
        { id: 'exclusion_list_ids', label: 'Excluded Lists', sortable: false },
        { id: 'exclusion_count', label: 'Excluded Units', sortable: true },
    ],
    Discount: [
        { id: 'discount_type', label: 'Discount Type', sortable: true },
        { id: 'discount_value', label: 'Discount Value', sortable: true },
    ],
} as const;

// Add helper function to format email addresses (near the other helper functions)
const formatEmailName = (email: string | null | undefined) => {
    if (!email) return '-';
    return email.split('@')[0];
};

export const PromotionsList: React.FC<PromotionsListProps> = ({
    currentUser,
    openUnitListModal,
    openDateListModal,
    setRefetchPromotions
}) => {
    const isApprover = currentUser?.approver;
    const {refetch, data: promotions, isFetching} = useGetAllPromotionsQuery();
    const {data: promotionOptions, isFetching: isFetchingPromotionOptions} = useGetPromotionOptionsQuery();
    const [updatePromotionApproval] = useApprovePromotionMutation();
    const { refetch: refetchUnitLists, data: unitLists, isFetching: isFetchingUnitLists} = useGetAllUnitListsQuery();
    const { refetch: refetchDateLists, data: dateLists, isFetching: isFetchingDateLists} = useGetAllDateListsQuery();
    const [addPromotion] = useAddPromotionMutation();
    const [updatePromotion] = useUpdatePromotionMutation();
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const levelCategories = UiUtils.levelCategories;

    useEffect(() => {
        // Create an async function that returns a Promise
        const refetchWithPromise = async () => {
            return await refetch();
        };
        setRefetchPromotions(refetchWithPromise);
    }, [refetch]);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [sortBy, setSortBy] = useState<keyof Promotion>('id');
    const [sortDirection, setSortDirection] = useState<SortDirection>('desc');
    const [dialogOpen, setDialogOpen] = useState(false);
    const [formMode, setFormMode] = useState<FormMode>('create');
    const [selectedPromotion, setSelectedPromotion] = useState<Promotion | null>(null);
    const [formData, setFormData] = useState<PromotionFormData>({
        name: '',
        description: 'Add a description',
        active: true,
        priority: 6000,
        channel: 'vacasa',
        strategy_type_id: 1,
        promo_type_id: 1,
        usage_type_id: 1,
        active_date_start: '',
        active_date_end: '',
        channel_promo_name: '',
        channel_promo_id: null,
        promo_code: '',
        stackable: false,
        marketing_included: false,
        merchandising: false,
        strikethrough: false,
        search_ranking: false,
        create_via_api: false,
        update_via_api: false,
        blackout_method: '',
        inclusion_list_ids: [],
        exclusion_list_ids: [],
        discount_type: 'percent',
        discount_value: null,
        stay_date_list_id: null,
        blackout_date_list_id: null,
    });

    const newUnitList: Omit<UnitList, 'id'> = {
        name: formData.name,
        admin_only: false,
        description: formData.description,
        source: null,
        source_values: null,
    };

    const newDateList: Omit<DateList, 'id'> = {
        name: formData.name,
        admin_only: false,
        description: formData.description,
        source: "manual",
        stay_date_start: null,
        stay_date_end: null,
        days_out_start: null,
        days_out_end: null,
        sunday_checked: true,
        monday_checked: true,
        tuesday_checked: true,
        wednesday_checked: true,
        thursday_checked: true,
        friday_checked: true,
        saturday_checked: true,
    }

    const [createUnitList] = useAddUnitListMutation();

    // Add new state
    const [searchQuery, setSearchQuery] = useState('');
    const [showNeedsApproval, setShowNeedsApproval] = useState(false);
    const [hideInactive, setHideInactive] = useState(true);
    const [hideExpired, setHideExpired] = useState(true);

    // Add helper function to get level from priority
    const getPriorityLevel = (priority: number) => {
        for (const level of UiUtils.levelCategories) {
            if (priority >= level.threshold) {
                return level;
            }
        }
        return levelCategories[levelCategories.length - 1];
    };

    // Add handler for level change
    const handleLevelChange = (newPriority: number) => {
        console.log(newPriority);
        setFormData(prev => ({
            ...prev,
            priority: newPriority
        }));
    };

    const handleSort = (column: keyof Promotion) => {
        if (sortBy === column) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortBy(column);
            setSortDirection('asc');
        }
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Add helper for formatting approval info
    const getApprovalTooltip = (promotion: Promotion) => {
        if (promotion.approved_status.toLowerCase() === 'approved') {
            return `Approved by ${promotion.approver_email} on ${formatDate(promotion.approved_at)}`;
        }
        if (promotion.approved_status.toLowerCase() === 'rejected') {
            return `Rejected by ${promotion.approver_email} on ${formatDate(promotion.approved_at)}\nReason: ${promotion.rejection_reason}`;
        }
        return 'Pending approval';
    };

    const formatDate = (date: string) => {
        try {
            // Add timezone offset to prevent date from shifting
            const d = new Date(date);
            const userTimezoneOffset = d.getTimezoneOffset() * 60000;
            return format(new Date(d.getTime() + userTimezoneOffset), 'MMM d, yyyy');
        } catch (e) {
            return 'Invalid date';
        }
    };

    const handleOpenDialog = (mode: FormMode, promotion?: Promotion) => {
        setFormMode(mode);
        setSelectedPromotion(promotion || null);
        setExpandedSection('basicInfo');  // Always reset to basicInfo
        setPromoCodeError('');
        if (mode === 'edit' && promotion) {
            setFormData({
                ...promotion
            });
        } else if (mode === 'copy' && promotion) {
            const isUnique = isPromoCodeUnique(promotion.promo_code);
            setPromoCodeError(isUnique ? '' : 'This promo code is already in use');
            setFormData({
                ...promotion,
                name: `${promotion.name} (Copy)`,
                promo_code: ''
            });
        } else {
            setFormData({
                name: '',
                description: 'Add a description',
                active: true,
                priority: 6000,
                channel: 'vacasa',
                strategy_type_id: 1,
                promo_type_id: 1,
                usage_type_id: 1,
                active_date_start: '',
                active_date_end: '',
                channel_promo_name: '',
                channel_promo_id: null,
                promo_code: '',
                stackable: false,
                marketing_included: false,
                merchandising: false,
                strikethrough: false,
                search_ranking: false,
                create_via_api: false,
                update_via_api: false,
                blackout_method: '',
                inclusion_list_ids: [],
                exclusion_list_ids: [],
                discount_type: 'percent',
                discount_value: null,
                stay_date_list_id: null,
                blackout_date_list_id: null,
            });
        }
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
        setSelectedPromotion(null);
    };

    const handleSubmit = async () => {
        // TODO: Add API calls for create/edit/copy
        let updatedPromoCopy = {...formData};

        Object.keys(updatedPromoCopy).forEach((key) => {
            if (updatedPromoCopy[key] === "") {
                updatedPromoCopy[key] = null;
            }
        });
        console.log(updatedPromoCopy);

        setIsSaving(true);

        const finish = async (response: any) => {
            setIsSaving(false);
            if (!!response["error"]) {
                console.log(response["error"]);
            }
            else {
                refetch();
                handleCloseDialog();
            }
        }

        if (formMode === 'edit') {
            await updatePromotion({id: selectedPromotion.id, data: updatedPromoCopy}).then(finish)
        }
        else { // CREATE or COPY
            await addPromotion(updatedPromoCopy).then(finish)
        }
    };

    // Add validation helpers
    const isValidActiveDateRange = (start: string | null, end: string | null) => {
        if (!start || !end) return true;
        return new Date(start) < new Date(end);
    };

    // Update date change handler with validation
    const handleDateChange = (field: keyof PromotionFormData) => (date: Date | null | 'Invalid Date') => {
        // Handle invalid or null dates
        if (!date || date === 'Invalid Date') {
            setFormData(prev => ({
                ...prev,
                [field]: null
            }));
            return;
        }

        try {
            // Ensure we have a valid date object
            const validDate = new Date(date.toISOString().split('T')[0]);
            if (isNaN(validDate.getTime())) {
                return;
            }

            // Format to YYYY-MM-DD
            const dateStr = validDate.toISOString().split('T')[0];

            setFormData(prev => {
                const newData = { ...prev, [field]: dateStr };

                // Set end date to day after start date when initially setting start date
                if (field === 'active_date_start' && (!prev.active_date_end || new Date(prev.active_date_end) < validDate)) {
                    newData.active_date_end = dateStr;
                }

                return newData;
            });
        } catch (error) {
            console.error('Invalid date:', error);
            // Keep the previous valid date
            return;
        }
    };

    // Add helper to check if promotion is expired
    const isPromotionExpired = (promotion: Promotion) => {
        if (!promotion.active_date_end) return false;
        return new Date(promotion.active_date_end) < new Date();
    };

    // Update the filtered and sorted data
    const filteredAndSortedData = React.useMemo(() => {
        if (!promotions) return [];

        return [...promotions]
            .filter(promotion => {
                // Filter by search query
                const searchLower = searchQuery.toLowerCase();
                const matchesSearch =
                    promotion.id.toString().includes(searchLower) ||
                    promotion.name.toLowerCase().includes(searchLower) ||
                    promotion.channel.toLowerCase().includes(searchLower) ||
                    getOptionName(promotion.strategy_type_id, promotionOptions?.strategy_types).toLowerCase().includes(searchLower) ||
                    getOptionName(promotion.promo_type_id, promotionOptions?.promotion_types).toLowerCase().includes(searchLower);

                // Filter by approval status
                const matchesApproval = !showNeedsApproval || promotion.approved_status.toLowerCase() !== 'approved';

                // Filter by active status - show if not hiding inactive or promotion is active
                const matchesActive = !hideInactive || promotion.active;

                // Filter by expiration - show if not hiding expired or promotion is not expired
                const matchesExpiration = !hideExpired || !isPromotionExpired(promotion);

                return matchesSearch && matchesApproval && matchesActive && matchesExpiration;
            })
            .sort((a, b) => {
                const aValue = a[sortBy];
                const bValue = b[sortBy];

                if (sortDirection === 'asc') {
                    return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
                } else {
                    return bValue < aValue ? -1 : bValue > aValue ? 1 : 0;
                }
            });
    }, [promotions, searchQuery, sortBy, sortDirection, showNeedsApproval, hideInactive, hideExpired, promotionOptions]);

    // Add paginatedData using filteredAndSortedData
    const paginatedData = React.useMemo(() => {
        const startIndex = page * rowsPerPage;
        return filteredAndSortedData.slice(startIndex, startIndex + rowsPerPage);
    }, [filteredAndSortedData, page, rowsPerPage]);

    // Add state for promo code error
    const [promoCodeError, setPromoCodeError] = useState<string>('');

    // Add validation helper
    const isPromoCodeUnique = (code: string, currentId?: number) => {
        if (!code || !promotions) return true;
        return !promotions.some(p =>
            p.promo_code === code &&
            // Allow same code if editing the same promotion
            p.id !== currentId
        );
    };

    // Update promo code change handler
    const handlePromoCodeChange = (code: string) => {
        // When copying, we don't pass currentId
        const currentId = formMode === 'edit' ? selectedPromotion?.id : undefined;
        const isUnique = isPromoCodeUnique(code, currentId);
        setPromoCodeError(isUnique ? '' : 'This promo code is already in use');

        setFormData(prev => ({
            ...prev,
            promo_code: code
        }));
    };

    // Add new state for approval dialog
    const [approvalDialogOpen, setApprovalDialogOpen] = useState(false);
    const [selectedPromotionForApproval, setSelectedPromotionForApproval] = useState<Promotion | null>(null);
    const [approvalStatus, setApprovalStatus] = useState('approved');
    const [rejectionReason, setRejectionReason] = useState('');
    const [isApproving, setIsApproving] = useState(false);

    // Add handler for opening approval dialog
    const handleOpenApprovalDialog = (promotion: Promotion) => {
        setSelectedPromotionForApproval(promotion);
        setApprovalStatus(promotion.approved_status || 'approved');
        setRejectionReason(promotion.rejection_reason || '');
        setApprovalDialogOpen(true);
    };

    // Add handler for submitting approval
    const handleApprovalSubmit = async () => {
        if (!selectedPromotionForApproval) return;

        setIsApproving(true);
        try {
            await updatePromotionApproval({
                id: selectedPromotionForApproval.id,
                approval_status: approvalStatus,
                reason: approvalStatus === 'rejected' ? rejectionReason : ""
            }).unwrap();

            await refetch();
            setApprovalDialogOpen(false);
        } catch (error) {
            console.error('Error updating approval status:', error);
        } finally {
            setIsApproving(false);
        }
    };

    // Add state for discount value error
    const [discountValueError, setDiscountValueError] = useState<string>('');

    // Add validation helper
    const validateDiscountValue = (value: string | number | null): string => {
        if (!value && value !== 0) return 'Discount value is required';

        const numValue = typeof value === 'string' ? parseFloat(value) : value;
        if (isNaN(numValue)) return 'Must be a number';
        if (numValue <= 0) return 'Must be greater than 0';
        if (!Number.isInteger(numValue)) return 'Must be a whole number';

        return '';
    };

    // Add state for expanded sections
    const [expandedSection, setExpandedSection] = useState<string | false>('basicInfo');

    // Add loading states
    const [isCreatingInclusionList, setIsCreatingInclusionList] = useState(false);
    const [isCreatingExclusionList, setIsCreatingExclusionList] = useState(false);

    // Update the handlers to show loading state
    const handleCreateInclusionList = async () => {
        setIsCreatingInclusionList(true);
        const inclusionUnitList = {
            ...newUnitList,
            name: `${formData.name} - Included Units`,
            description: `Units included in promotion: ${formData.name}`
        };

        try {
            const newUnitListID = await createUnitList(inclusionUnitList).unwrap();
            console.log(newUnitListID);
            refetchUnitLists();
            setFormData(prev => ({
                ...prev,
                inclusion_list_ids: [...(prev.inclusion_list_ids || []), newUnitListID]
            }));
        } catch (error) {
            console.error('Failed to create inclusion unit list:', error);
        } finally {
            setIsCreatingInclusionList(false);
        }
    };

    const handleCreateExclusionList = async () => {
        setIsCreatingExclusionList(true);
        const exclusionUnitList = {
            ...newUnitList,
            name: `${formData.name} - Excluded Units`,
            description: `Units excluded from promotion: ${formData.name}`
        };

        try {
            const newUnitListID = await createUnitList(exclusionUnitList).unwrap();
            console.log(newUnitListID);
            refetchUnitLists();
            setFormData(prev => ({
                ...prev,
                exclusion_list_ids: [...(prev.exclusion_list_ids || []), newUnitListID]
            }));
        } catch (error) {
            console.error('Failed to create exclusion unit list:', error);
        } finally {
            setIsCreatingExclusionList(false);
        }
    };

    // Add new state variables for date list creation
    const [isCreatingStayDateList, setIsCreatingStayDateList] = useState(false);
    const [isCreatingBlackoutDateList, setIsCreatingBlackoutDateList] = useState(false);
    const [createDateList] = useAddDateListMutation();

    // Add handlers for creating date lists
    const handleCreateStayDateList = async () => {
        setIsCreatingStayDateList(true);
        const stayDateList = {
            ...newDateList,
            name: `${formData.name} - Stay Dates`,
            description: `Stay dates for promotion: ${formData.name}`
        };

        try {
            const newDateListID = await createDateList(stayDateList).unwrap();
            refetchDateLists();
            setFormData(prev => ({
                ...prev,
                stay_date_list_id: newDateListID
            }));
        } catch (error) {
            console.error('Failed to create stay date list:', error);
        } finally {
            setIsCreatingStayDateList(false);
        }
    };

    const handleCreateBlackoutDateList = async () => {
        setIsCreatingBlackoutDateList(true);
        const blackoutDateList = {
            ...newDateList,
            name: `${formData.name} - Blackout Dates`,
            description: `Blackout dates for promotion: ${formData.name}`
        };

        try {
            const newDateListID = await createDateList(blackoutDateList).unwrap();
            refetchDateLists();
            setFormData(prev => ({
                ...prev,
                blackout_date_list_id: newDateListID
            }));
        } catch (error) {
            console.error('Failed to create blackout date list:', error);
        } finally {
            setIsCreatingBlackoutDateList(false);
        }
    };

    const renderCellContent = (promotion: Promotion, columnId: keyof Promotion) => {
        switch (columnId) {
            case 'strategy_type_id':
                return getOptionName(promotion.strategy_type_id, promotionOptions?.strategy_types);
            case 'promo_type_id':
                return getOptionName(promotion.promo_type_id, promotionOptions?.promotion_types);
            case 'active':
                return (
                    <Chip
                        label={promotion.active ? 'Active' : 'Inactive'}
                        color={promotion.active ? 'primary' : 'default'}
                        size="small"
                    />
                );
            case 'active_date_start':
            case 'active_date_end':
            case 'stay_date_start':
            case 'stay_date_end':
                return formatDate(promotion[columnId]);
            case 'inclusion_list_ids':
                return (
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                        {promotion.inclusion_list_ids?.map(id => {
                            const list = unitLists?.find(l => l.id === id);
                            return (
                                <Chip
                                    key={id}
                                    label={`${id}: ${list?.name || 'Unknown'}`}
                                    size="small"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        openUnitListModal(id);
                                    }}
                                    style={{ cursor: 'pointer' }}
                                />
                            );
                        })}
                    </div>
                );
            case 'inclusion_count':
                return (
                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        {promotion.inclusion_count || 0}
                        {!promotion.inclusion_count && promotion.inclusion_list_ids?.length > 0 && (
                            <Tooltip title="Unit list has no units. Click to edit.">
                                <IconButton
                                    size="small"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        const emptyListId = promotion.inclusion_list_ids[0];
                                        openUnitListModal(emptyListId);
                                    }}
                                    style={{ padding: 4 }}
                                >
                                    <Icon.AlertTriangle
                                        height={16}
                                        width={16}
                                        stroke="orange"
                                    />
                                </IconButton>
                            </Tooltip>
                        )}
                    </div>
                );
            case 'exclusion_list_ids':
                return (
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                        {promotion.exclusion_list_ids?.map(id => {
                            const list = unitLists?.find(l => l.id === id);
                            return (
                                <Chip
                                    key={id}
                                    label={`${id}: ${list?.name || 'Unknown'}`}
                                    size="small"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        openUnitListModal(id);
                                    }}
                                    style={{ cursor: 'pointer' }}
                                />
                            );
                        })}
                    </div>
                );
            case 'exclusion_count':
                return (
                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        {promotion.exclusion_count || 0}
                        {!promotion.exclusion_count && promotion.exclusion_list_ids?.length > 0 && (
                            <Tooltip title="Unit list has no units. Click to edit.">
                                <IconButton
                                    size="small"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        const emptyListId = promotion.exclusion_list_ids[0];
                                        openUnitListModal(emptyListId);
                                    }}
                                    style={{ padding: 4 }}
                                >
                                    <Icon.AlertTriangle
                                        height={16}
                                        width={16}
                                        stroke="orange"
                                    />
                                </IconButton>
                            </Tooltip>
                        )}
                    </div>
                );
            case 'approved_status':
                return (
                    <Tooltip title={getApprovalTooltip(promotion)}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            {getStatusInfo(promotion.approved_status).icon}
                            <span style={{ color: getStatusInfo(promotion.approved_status).color }}>
                                {promotion.approved_status}
                            </span>
                        </div>
                    </Tooltip>
                );
            case 'discount_value':
                if (promotion.discount_value === null) return null;
                return promotion.discount_type === 'dollar'
                    ? `$${promotion.discount_value}`
                    : `${promotion.discount_value}%`;
            case 'discount_type':
                return promotion.discount_type.charAt(0).toUpperCase() + promotion.discount_type.slice(1);
            case 'days_of_week':
                return formatDaysOfWeek(promotion);
            case 'usage_type_id':
                return getOptionName(promotion.usage_type_id, promotionOptions?.usage_types);
            case 'priority':
                return (
                    <Tooltip title={`Level ${getPriorityLevel(promotion.priority).category}`}>
                        <span>{promotion.priority}</span>
                    </Tooltip>
                );
            case 'blackout_method':
                return promotion.blackout_method 
                    ? promotion.blackout_method.charAt(0).toUpperCase() + promotion.blackout_method.slice(1)
                    : 'None';
            case 'stackable':
            case 'merchandising':
            case 'strikethrough':
            case 'search_ranking':
            case 'marketing_included':
            case 'create_via_api':
            case 'update_via_api':
                return (
                    <Chip
                        label={promotion[columnId] ? 'Yes' : 'No'}
                        size="small"
                        color={promotion[columnId] ? 'primary' : 'default'}
                    />
                );
            case 'creator':
                return (
                    <Tooltip title={`Created: ${formatDate(promotion.created_at)}`}>
                        <span>{formatEmailName(promotion.creator)}</span>
                    </Tooltip>
                );
            case 'created_at':
                return formatDate(promotion.created_at);
            case 'updater':
                return promotion.updater ? (
                    <Tooltip title={`Updated: ${formatDate(promotion.updated_at)}`}>
                        <span>{formatEmailName(promotion.updater)}</span>
                    </Tooltip>
                ) : null;
            case 'updated_at':
                return promotion.updated_at ? formatDate(promotion.updated_at) : null;
            case 'stay_date_list_id':
                const dateList = dateLists?.find(l => l.id === promotion.stay_date_list_id);
                return dateList ? (
                    <Chip
                        label={dateList.name}
                        size="small"
                        onClick={(e) => {
                            e.stopPropagation();
                            openDateListModal(dateList.id)
                        }}
                    />
                ) : null;
            case 'blackout_date_list_id':
                const blackoutList = dateLists?.find(l => l.id === promotion.blackout_date_list_id);
                return blackoutList ? (
                    <Chip
                        label={blackoutList.name}
                        size="small"
                        onClick={(e) => {
                            e.stopPropagation();
                            openDateListModal(blackoutList.id)
                        }}
                    />
                ) : null;
            case 'nights':
                return (
                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        {promotion.nights || 0}
                        {!promotion.nights && !!promotion.stay_date_list_id && (
                            <Tooltip title="Date list has no dates. Click to edit.">
                                <IconButton
                                    size="small"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        const emptyListId = promotion.stay_date_list_id;
                                        openDateListModal(emptyListId);
                                    }}
                                    style={{ padding: 4 }}
                                >
                                    <Icon.AlertTriangle
                                        height={16}
                                        width={16}
                                        stroke="orange"
                                    />
                                </IconButton>
                            </Tooltip>
                        )}
                    </div>
                );
            case 'blackout_nights':
                return (
                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        {promotion.blackout_nights || 0}
                        {!promotion.blackout_nights && !!promotion.blackout_date_list_id && (
                            <Tooltip title="Blackout list has no dates. Click to edit.">
                                <IconButton
                                    size="small"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        const emptyListId = promotion.blackout_date_list_id;
                                        openDateListModal(emptyListId);
                                    }}
                                    style={{ padding: 4 }}
                                >
                                    <Icon.AlertTriangle
                                        height={16}
                                        width={16}
                                        stroke="orange"
                                    />
                                </IconButton>
                            </Tooltip>
                        )}
                    </div>
                );
            default:
                return promotion[columnId];
        }
    };

    // Column configuration (move inside component but keep outside state)
    const availableColumns: ReadonlyArray<ColumnConfig> = [
        { id: 'id', label: 'ID', sortable: true },
        { id: 'name', label: 'Name', sortable: true },
        { id: 'channel', label: 'Channel', sortable: true },
        { id: 'strategy_type_id', label: 'Strategy Type', sortable: true },
        { id: 'promo_type_id', label: 'Promotion Type', sortable: true },
        { id: 'usage_type_id', label: 'Usage Type', sortable: true },
        { id: 'priority', label: 'Priority', sortable: true },
        { id: 'inclusion_list_ids', label: 'Included Lists', sortable: false },
        { id: 'exclusion_list_ids', label: 'Excluded Lists', sortable: false },
        { id: 'inclusion_count', label: 'Included Units', sortable: true },
        { id: 'exclusion_count', label: 'Excluded Units', sortable: true },
        { id: 'active', label: 'Status', sortable: true },
        { id: 'approved_status', label: 'Approval', sortable: true },
        { id: 'promo_code', label: 'Promo Code', sortable: true },
        { id: 'active_dates', label: 'Active Date Range', sortable: true },
        { id: 'active_date_start', label: 'Active From', sortable: true },
        { id: 'active_date_end', label: 'Active Until', sortable: true },
        { id: 'stay_date_list_id', label: 'Date List', sortable: true },
        { id: 'nights', label: 'Nights', sortable: true },
        { id: 'blackout_date_list_id', label: 'Blackout List', sortable: true },
        { id: 'blackout_nights', label: 'Blackout Nights', sortable: true },
        { id: 'stay_dates', label: 'Stay Date Range', sortable: true },
        { id: 'stay_date_start', label: 'Stay From', sortable: true },
        { id: 'stay_date_end', label: 'Stay Until', sortable: true },
        { id: 'days_of_week', label: 'Days', sortable: false },
        { id: 'discount_type', label: 'Discount Type', sortable: true },
        { id: 'discount_value', label: 'Discount Value', sortable: true },
        { id: 'blackout_method', label: 'Blackout Method', sortable: true },
        { id: 'stackable', label: 'Stackable', sortable: true },
        { id: 'merchandising', label: 'Merchandising', sortable: true },
        { id: 'strikethrough', label: 'Strikethrough', sortable: true },
        { id: 'search_ranking', label: 'Search Ranking', sortable: true },
        { id: 'marketing_included', label: 'Marketing', sortable: true },
        { id: 'create_via_api', label: 'Create via API', sortable: true },
        { id: 'update_via_api', label: 'Update via API', sortable: true },
        { id: 'creator', label: 'Creator', sortable: true },
        { id: 'created_at', label: 'Created', sortable: true },
        { id: 'updater', label: 'Last Updated By', sortable: true },
        { id: 'updated_at', label: 'Last Updated', sortable: true },
    ];

    // Move state declarations inside component
    const [visibleColumns, setVisibleColumns] = useState<Set<keyof Promotion>>(() => {
        const stored = sessionStorage.getItem('promotionTableColumns');
        if (stored) {
            try {
                const parsed = JSON.parse(stored);
                const validColumns = parsed.filter((key: string): key is keyof Promotion => 
                    availableColumns.some(col => col.id === key)
                );
                return new Set(validColumns);
            } catch {
                return new Set(['id', 'name', 'channel', 'strategy_type_id', 'promo_type_id', 
                              'inclusion_count', 'exclusion_count', 'approved_status', 'creator'] as const);
            }
        }
        return new Set(['id', 'name', 'channel', 'strategy_type_id', 'promo_type_id', 
                       'inclusion_count', 'exclusion_count', 'approved_status', 'creator'] as const);
    });

    const [columnSelectorAnchor, setColumnSelectorAnchor] = useState<null | HTMLElement>(null);

    // Update the handleColumnVisibilityChange function
    const handleColumnVisibilityChange = (columnId: keyof Promotion) => {
        const newVisibleColumns = new Set(visibleColumns);
        if (newVisibleColumns.has(columnId)) {
            if (newVisibleColumns.size > 1) {
                newVisibleColumns.delete(columnId);
            }
        } else {
            newVisibleColumns.add(columnId);
        }
        setVisibleColumns(newVisibleColumns);
        // Convert Set to Array before storing
        sessionStorage.setItem('promotionTableColumns', 
            JSON.stringify(Array.from(newVisibleColumns)));
    };

    // Add helper function to format days of week display
    const formatDaysOfWeek = (promotion: Promotion) => {
        const days = [
            { key: 'sunday', label: 'S' },
            { key: 'monday', label: 'M' },
            { key: 'tuesday', label: 'T' },
            { key: 'wednesday', label: 'W' },
            { key: 'thursday', label: 'T' },
            { key: 'friday', label: 'F' },
            { key: 'saturday', label: 'S' }
        ];

        return (
            <div style={{ display: 'flex', gap: '2px' }}>
                {days.map(day => (
                    <div
                        key={day.key}
                        style={{
                            width: '20px',
                            height: '20px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: '4px',
                            backgroundColor: promotion[`${day.key}_checked`] ? '#1976d2' : '#e0e0e0',
                            color: promotion[`${day.key}_checked`] ? 'white' : '#757575',
                            fontSize: '12px'
                        }}
                    >
                        {day.label}
                    </div>
                ))}
            </div>
        );
    };

    return (
        <Paper>
            <Box style={{
                display: 'flex',
                gap: '16px',
                marginBottom: '16px',
                alignItems: 'center',
                padding: '16px',
                justifyContent: 'space-between'
            }}>
                <div style={{display: 'flex', gap: '16px', alignItems: 'center'}}>
                    <TextField
                        label="Search Promotions"
                        variant="outlined"
                        style={{width: "400px"}}
                        size="small"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={showNeedsApproval}
                                onChange={(e) => setShowNeedsApproval(e.target.checked)}
                            />
                        }
                        label="Needs Approval"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={hideInactive}
                                onChange={(e) => setHideInactive(e.target.checked)}
                            />
                        }
                        label="Hide Inactive"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={hideExpired}
                                onChange={(e) => setHideExpired(e.target.checked)}
                            />
                        }
                        label="Hide Expired"
                    />
                </div>
                <div style={{display: 'flex', gap: '8px'}}>
                    <Tooltip title={isFetching ? "Loading..." : "Refresh data"}>
                        <IconButton
                            onClick={refetch}
                            disabled={isFetching}
                            size="small"
                        >
                            {isFetching ? (
                                <CircularProgress size={20}/>
                            ) : (
                                <Icon.RefreshCCW height={20} width={20}/>
                            )}
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Customize columns">
                        <IconButton
                            onClick={(e) => setColumnSelectorAnchor(e.currentTarget)}
                            size="small"
                        >
                            <Icon.Settings height={20} width={20}/>
                        </IconButton>
                    </Tooltip>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleOpenDialog('create')}
                        startIcon={<Icon.Plus height={20} width={20}/>}
                    >
                        New Promotion
                    </Button>
                </div>
            </Box>

            <div style={{ position: 'relative' }}>
                {isFetching && (
                    <div style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(255, 255, 255, 0.7)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 1,
                    }}>
                        <CircularProgress />
                    </div>
                )}

                <TableContainer style={{
                    height: '575px',
                    overflow: 'auto',
                    position: 'relative',
                    zIndex: 1
                }}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                {availableColumns
                                    .filter(column => visibleColumns.has(column.id))
                                    .map(column => (
                                        <TableCell
                                            key={column.id}
                                            className="sticky-header-cell"
                                            style={{ minWidth: column.minWidth }}
                                        >
                                            {column.sortable ? (
                                                <TableSortLabel
                                                    active={sortBy === column.id}
                                                    direction={sortDirection}
                                                    onClick={() => handleSort(column.id)}
                                                >
                                                    {column.label}
                                                </TableSortLabel>
                                            ) : column.label}
                                        </TableCell>
                                    ))}
                                <TableCell className="sticky-header-cell">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {paginatedData.map((promotion) => (
                                <Tooltip 
                                    key={promotion.id}
                                    title={promotion.description || 'No description'}
                                    placement="bottom-start"
                                    enterDelay={500}
                                >
                                    <TableRow 
                                        onClick={() => handleOpenDialog('edit', promotion)}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        {availableColumns
                                            .filter(column => visibleColumns.has(column.id))
                                            .map(column => (
                                                <TableCell key={column.id}>
                                                    {renderCellContent(promotion, column.id)}
                                                </TableCell>
                                            ))}
                                        <TableCell>
                                            <div style={{ display: 'flex', gap: '4px' }}>
                                                <Tooltip title="Copy">
                                                    <IconButton
                                                        size="small"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleOpenDialog('copy', promotion);
                                                        }}
                                                    >
                                                        <Icon.Copy height={16} width={16} />
                                                    </IconButton>
                                                </Tooltip>
                                                {isApprover && (
                                                    <Tooltip title="Update Approval">
                                                        <IconButton
                                                            size="small"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                handleOpenApprovalDialog(promotion);
                                                            }}
                                                        >
                                                            <Icon.CheckSquare height={16} width={16} />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                </Tooltip>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>

            <TablePagination
                style={{ borderTop: '1px solid rgba(224, 224, 224, 1)' }}
                rowsPerPageOptions={[5, 10, 25, 50]}
                component="div"
                count={filteredAndSortedData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />

            <Popover
                open={Boolean(columnSelectorAnchor)}
                anchorEl={columnSelectorAnchor}
                onClose={() => setColumnSelectorAnchor(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Box p={2} style={{ maxHeight: '400px', overflow: 'auto' }}>
                    <Typography variant="subtitle2" gutterBottom>
                        Visible Columns
                    </Typography>
                    {Object.entries(columnGroups).map(([groupName, columns]) => (
                        <div key={groupName}>
                            <Typography 
                                variant="subtitle2" 
                                style={{ 
                                    marginTop: '16px', 
                                    marginBottom: '8px',
                                    color: '#666',
                                    fontSize: '0.875rem'
                                }}
                            >
                                {groupName}
                            </Typography>
                            <FormGroup>
                                {columns.map((column) => (
                                    <FormControlLabel
                                        key={column.id}
                                        control={
                                            <Checkbox
                                                checked={visibleColumns.has(column.id)}
                                                onChange={() => handleColumnVisibilityChange(column.id)}
                                                disabled={visibleColumns.has(column.id) && visibleColumns.size === 1}
                                            />
                                        }
                                        label={column.label}
                                    />
                                ))}
                            </FormGroup>
                        </div>
                    ))}
                </Box>
            </Popover>
            <Dialog
                open={dialogOpen}
                onClose={handleCloseDialog}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle>
                    {formMode === 'create' ? 'Create New Promotion' : 
                     formMode === 'edit' ? 'Edit Promotion' : 
                     'Copy Promotion'}
                </DialogTitle>
                <DialogContent>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '16px', padding: '16px 0' }}>
                            {/* Basic Info Section */}
                            <Accordion 
                                expanded={expandedSection === 'basicInfo'} 
                                onChange={() => setExpandedSection(expandedSection === 'basicInfo' ? false : 'basicInfo')}
                                defaultExpanded
                            >
                                <AccordionSummary 
                                    expandIcon={
                                        <Icon.ChevronDown 
                                            style={chevronStyle(expandedSection === 'basicInfo')}
                                            height={20} 
                                            width={20} 
                                        />
                                    }
                                >
                                    <Typography variant="h6">Basic Information</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '16px', width: '100%' }}>
                                        <div style={{ display: 'flex', gap: '16px' }}>
                                            <TextField
                                                label="Name"
                                                value={formData.name}
                                                onChange={(e) => setFormData(prev => ({...prev, name: e.target.value}))}
                                                style={{ width: '50%' }}
                                            />
                                            <FormControlLabel
                                                style={{ width: '25%' }}
                                                control={
                                                    <Switch
                                                        checked={formData.active}
                                                        onChange={(e) => setFormData(prev => ({...prev, active: e.target.checked}))}
                                                    />
                                                }
                                                label="Active"
                                            />
                                        </div>
                                        <TextField
                                            label="Description"
                                            value={formData.description}
                                            onChange={(e) => setFormData(prev => ({...prev, description: e.target.value}))}
                                            fullWidth
                                        />
                                        <div style={{ display: 'flex', gap: '16px' }}>
                                            <TextField
                                                label="Priority"
                                                type="number"
                                                value={formData.priority}
                                                onChange={(e) => setFormData(prev => ({
                                                    ...prev,
                                                    priority: parseInt(e.target.value) || 0
                                                }))}
                                                style={{ width: '50%' }}
                                            />
                                            <FormControl style={{ width: '50%' }}>
                                                <InputLabel>Level</InputLabel>
                                                <Select
                                                    value={getPriorityLevel(formData.priority).threshold}
                                                    onChange={(e) => handleLevelChange(parseInt(e.target.value as string))}
                                                >
                                                    {levelCategories.map(level => (
                                                        <MenuItem 
                                                            key={level.category}
                                                            value={level.threshold}
                                                        >
                                                            {`${level.category} (Priority >= ${level.threshold})`}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                </AccordionDetails>
                            </Accordion>

                            {/* Channel Info Section */}
                            <Accordion 
                                expanded={expandedSection === 'channelInfo'}
                                onChange={() => setExpandedSection(expandedSection === 'channelInfo' ? false : 'channelInfo')}
                            >
                                <AccordionSummary 
                                    expandIcon={
                                        <Icon.ChevronDown 
                                            style={chevronStyle(expandedSection === 'channelInfo')}
                                            height={20} 
                                            width={20} 
                                        />
                                    }
                                >
                                    <Typography variant="h6">Channel Information</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '16px', width: '100%' }}>
                                        <FormControl fullWidth>
                                            <InputLabel>Channel</InputLabel>
                                            <Select
                                                value={formData.channel}
                                                onChange={(e) => {
                                                    const newChannel = e.target.value as string;
                                                    setFormData(prev => ({
                                                        ...prev,
                                                        channel: newChannel,
                                                        strategy_type_id: null,
                                                        promo_type_id: null
                                                    }));
                                                }}
                                            >
                                                <MenuItem value="vacasa">Vacasa</MenuItem>
                                                <MenuItem value="vrbo">VRBO</MenuItem>
                                                <MenuItem value="airbnb">Airbnb</MenuItem>
                                                <MenuItem value="bdc">Booking.com</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <div style={{ display: 'flex', gap: '16px' }}>
                                            <FormControl fullWidth>
                                                <InputLabel>Strategy Type</InputLabel>
                                                <Select
                                                    value={formData.strategy_type_id || ''}
                                                    onChange={(e) => setFormData(prev => ({
                                                        ...prev,
                                                        strategy_type_id: e.target.value as number
                                                    }))}
                                                >
                                                    {getChannelOptions(promotionOptions?.strategy_types, formData.channel)
                                                        .map(type => (
                                                            <MenuItem key={type.id} value={type.id}>
                                                                {type.name}
                                                            </MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                            </FormControl>
                                            <FormControl fullWidth>
                                                <InputLabel>Promotion Type</InputLabel>
                                                <Select
                                                    value={formData.promo_type_id || ''}
                                                    onChange={(e) => setFormData(prev => ({
                                                        ...prev,
                                                        promo_type_id: e.target.value as number
                                                    }))}
                                                >
                                                    {getChannelOptions(promotionOptions?.promotion_types, formData.channel)
                                                        .map(type => (
                                                            <MenuItem key={type.id} value={type.id}>
                                                                {type.name}
                                                            </MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                            </FormControl>
                                            <FormControl fullWidth>
                                                <InputLabel>Usage Type</InputLabel>
                                                <Select
                                                    value={formData.usage_type_id || ''}
                                                    onChange={(e) => setFormData(prev => ({
                                                        ...prev,
                                                        usage_type_id: e.target.value as number
                                                    }))}
                                                >
                                                    {promotionOptions?.usage_types?.map(type => (
                                                        <MenuItem key={type.id} value={type.id}>
                                                            {type.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div style={{ display: 'flex', gap: '16px' }}>
                                            <TextField
                                                label="Channel Promo Name"
                                                value={formData.channel_promo_name}
                                                onChange={(e) => setFormData(prev => ({
                                                    ...prev,
                                                    channel_promo_name: e.target.value
                                                }))}
                                                fullWidth
                                            />
                                            <TextField
                                                label="Channel Promo ID"
                                                type="number"
                                                value={formData.channel_promo_id}
                                                onChange={(e) => setFormData(prev => ({
                                                    ...prev,
                                                    channel_promo_id: parseInt(e.target.value) || 0
                                                }))}
                                                fullWidth
                                            />
                                            <TextField
                                                label="Promo Code"
                                                value={formData.promo_code}
                                                onChange={(e) => handlePromoCodeChange(e.target.value)}
                                                fullWidth
                                                error={!!promoCodeError}
                                                helperText={promoCodeError}
                                                required
                                            />
                                        </div>
                                    </div>
                                </AccordionDetails>
                            </Accordion>

                            {/* Features Section */}
                            <Accordion 
                                expanded={expandedSection === 'features'}
                                onChange={() => setExpandedSection(expandedSection === 'features' ? false : 'features')}
                            >
                                <AccordionSummary 
                                    expandIcon={
                                        <Icon.ChevronDown 
                                            style={chevronStyle(expandedSection === 'features')}
                                            height={20} 
                                            width={20} 
                                        />
                                    }
                                >
                                    <Typography variant="h6">Feature Flags</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '16px', width: '100%' }}>
                                        <div style={{ display: 'flex', gap: '16px', flexWrap: 'wrap' }}>
                                            {[
                                                'stackable', 'merchandising', 'strikethrough', 'search_ranking',
                                                'marketing_included', 'create_via_api', 'update_via_api'
                                            ].map(feature => (
                                                <FormControlLabel
                                                    key={feature}
                                                    control={
                                                        <Switch
                                                            checked={formData[feature]}
                                                            onChange={(e) => setFormData(prev => ({
                                                                ...prev,
                                                                [feature]: e.target.checked
                                                            }))}
                                                        />
                                                    }
                                                    label={feature.split('_').map(word => 
                                                        word.charAt(0).toUpperCase() + word.slice(1)
                                                    ).join(' ')}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                </AccordionDetails>
                            </Accordion>

                            {/* Date Ranges Section */}
                            <Accordion 
                                expanded={expandedSection === 'dateRanges'}
                                onChange={() => setExpandedSection(expandedSection === 'dateRanges' ? false : 'dateRanges')}
                            >
                                <AccordionSummary 
                                    expandIcon={
                                        <Icon.ChevronDown 
                                            style={chevronStyle(expandedSection === 'dateRanges')}
                                            height={20} 
                                            width={20} 
                                        />
                                    }
                                >
                                    <Typography variant="h6">Dates</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '16px', width: '100%' }}>
                                        {/* Active Dates */}
                                        <div style={{ display: 'flex', gap: '16px' }}>
                                            <KeyboardDatePicker
                                                margin="normal"
                                                label="Active Date Start"
                                                format="MM/dd/yyyy"
                                                value={formData.active_date_start ? new Date(`${formData.active_date_start}T12:00:00Z`) : null}
                                                onChange={handleDateChange('active_date_start')}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change active start date',
                                                }}
                                                fullWidth
                                                invalidDateMessage="Invalid date format (MM/DD/YYYY)"
                                                maxDate={new Date('2099-12-31')}
                                                minDate={new Date('2000-01-01')}
                                            />
                                            <KeyboardDatePicker
                                                margin="normal"
                                                label="Active Date End"
                                                format="MM/dd/yyyy"
                                                value={formData.active_date_end ? new Date(`${formData.active_date_end}T12:00:00Z`) : null}
                                                onChange={handleDateChange('active_date_end')}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change active end date',
                                                }}
                                                fullWidth
                                                minDate={formData.active_date_start ? new Date(`${formData.active_date_start}T12:00:00Z`) : undefined}
                                                minDateMessage="End date must be after start date"
                                                invalidDateMessage="Invalid date format (MM/DD/YYYY)"
                                                maxDate={new Date('2099-12-31')}
                                                disabled={!formData.active_date_start}
                                            />
                                        </div>

                                        {/* Date Lists */}
                                        <div style={{ display: 'flex', gap: '16px' }}>
                                            <FormControl fullWidth>
                                                <div style={{ 
                                                    display: 'flex', 
                                                    justifyContent: 'space-between', 
                                                    alignItems: 'flex-start', 
                                                    marginBottom: '8px' 
                                                }}>
                                                    <InputLabel 
                                                        style={{ 
                                                            position: 'relative',
                                                            background: 'white',
                                                            padding: '0 4px'
                                                        }}
                                                    >
                                                        Stay Date List
                                                    </InputLabel>
                                                    <Tooltip title={!formData.name.trim() ? 
                                                        'Enter promotion name before creating a new date list' : 
                                                        'Create new stay date list'
                                                    }>
                                                        <span>
                                                            <Button
                                                                size="small"
                                                                startIcon={isCreatingStayDateList ? 
                                                                    <CircularProgress size={16} /> : 
                                                                    <Icon.Plus height={16} width={16} />
                                                                }
                                                                onClick={handleCreateStayDateList}
                                                                disabled={!formData.name.trim() || isCreatingStayDateList}
                                                                style={{ 
                                                                    opacity: !formData.name.trim() ? 0.6 : 1,
                                                                    position: 'relative'
                                                                }}
                                                            >
                                                                New List
                                                            </Button>
                                                        </span>
                                                    </Tooltip>
                                                </div>
                                                <Select
                                                    value={formData.stay_date_list_id || ''}
                                                    onChange={(e) => setFormData(prev => ({
                                                        ...prev,
                                                        stay_date_list_id: e.target.value as number
                                                    }))}
                                                    displayEmpty
                                                    renderValue={(selected) => {
                                                        if (!selected) return <em>Select a date list</em>;
                                                        const list = dateLists?.find(l => l.id === selected);
                                                        return list ? list.name : selected;
                                                    }}
                                                    disabled={isFetchingDateLists}
                                                >
                                                    <MenuItem value="">
                                                        <em>None</em>
                                                    </MenuItem>
                                                    {dateLists?.map(list => (
                                                        <MenuItem key={list.id} value={list.id}>
                                                            {list.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>

                                            <FormControl fullWidth>
                                                <div style={{ 
                                                    display: 'flex', 
                                                    justifyContent: 'space-between', 
                                                    alignItems: 'flex-start', 
                                                    marginBottom: '8px' 
                                                }}>
                                                    <InputLabel 
                                                        style={{ 
                                                            position: 'relative',
                                                            background: 'white',
                                                            padding: '0 4px'
                                                        }}
                                                    >
                                                        Blackout Date List
                                                    </InputLabel>
                                                    <Button
                                                        size="small"
                                                        startIcon={isCreatingBlackoutDateList ? 
                                                            <CircularProgress size={16} /> : 
                                                            <Icon.Plus height={16} width={16} />
                                                        }
                                                        onClick={handleCreateBlackoutDateList}
                                                        disabled={!formData.name.trim() || isCreatingBlackoutDateList}
                                                        style={{ 
                                                            opacity: !formData.name.trim() ? 0.6 : 1,
                                                            position: 'relative'
                                                        }}
                                                        title={!formData.name.trim() ? 
                                                            'Enter promotion name before creating a new date list' : 
                                                            'Create new blackout date list'
                                                        }
                                                    >
                                                        New List
                                                    </Button>
                                                </div>
                                                <Select
                                                    value={formData.blackout_date_list_id || ''}
                                                    onChange={(e) => setFormData(prev => ({
                                                        ...prev,
                                                        blackout_date_list_id: e.target.value as number
                                                    }))}
                                                    displayEmpty
                                                    renderValue={(selected) => {
                                                        if (!selected) return <em>Select a blackout date list</em>;
                                                        const list = dateLists?.find(l => l.id === selected);
                                                        return list ? list.name : selected;
                                                    }}
                                                    disabled={isFetchingDateLists}
                                                >
                                                    <MenuItem value="">
                                                        <em>None</em>
                                                    </MenuItem>
                                                    {dateLists?.map(list => (
                                                        <MenuItem key={list.id} value={list.id}>
                                                            {list.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                </AccordionDetails>
                            </Accordion>

                            {/* Unit Lists Section */}
                            <Accordion
                                expanded={expandedSection === 'unitLists'}
                                onChange={() => setExpandedSection(expandedSection === 'unitLists' ? false : 'unitLists')}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <Icon.ChevronDown
                                            style={chevronStyle(expandedSection === 'unitLists')}
                                            height={20}
                                            width={20}
                                        />
                                    }
                                >
                                    <Typography variant="h6">Unit Lists</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '16px', width: '100%' }}>
                                        <div style={{ display: 'flex', gap: '16px' }}>
                                            <FormControl fullWidth>
                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', marginBottom: '8px' }}>
                                                    <InputLabel style={{ position: 'relative' }}>Inclusion Lists</InputLabel>
                                                    <Button
                                                        size="small"
                                                        startIcon={isCreatingInclusionList ? 
                                                            <CircularProgress size={16} /> : 
                                                            <Icon.Plus height={16} width={16} />
                                                        }
                                                        onClick={handleCreateInclusionList}
                                                        disabled={!formData.name.trim() || isCreatingInclusionList}
                                                        style={{ 
                                                            opacity: !formData.name.trim() ? 0.6 : 1,
                                                            position: 'relative'
                                                        }}
                                                        title={!formData.name.trim() ? 
                                                            'Enter promotion name before creating a new unit list' : 
                                                            'Create new inclusion list'
                                                        }
                                                    >
                                                        New List
                                                    </Button>
                                                </div>
                                                <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', marginTop: '16px' }}>
                                                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '4px', margin: '8px 0' }}>
                                                        {(formData.inclusion_list_ids || []).map((id) => {
                                                            const list = unitLists?.find(l => l.id === id);
                                                            return (
                                                                <Chip
                                                                    key={id}
                                                                    label={list?.name || id}
                                                                    onDelete={() => {
                                                                        setFormData(prev => ({
                                                                            ...prev,
                                                                            inclusion_list_ids: prev.inclusion_list_ids.filter(listId => listId !== id)
                                                                        }));
                                                                    }}
                                                                    size="small"
                                                                />
                                                            );
                                                        })}
                                                    </div>
                                                    <Select
                                                        value=""
                                                        onChange={(e) => {
                                                            const newValue = e.target.value as number;
                                                            if (newValue) {
                                                                setFormData(prev => ({
                                                                    ...prev,
                                                                    inclusion_list_ids: [...(prev.inclusion_list_ids || []), newValue]
                                                                }));
                                                            }
                                                        }}
                                                        displayEmpty
                                                        renderValue={() => <em>
                                                            {isFetchingUnitLists ? 'Loading...' : 'Select lists to include'}
                                                        </em>}
                                                        disabled={isFetchingUnitLists}
                                                    >
                                                        {unitLists
                                                            ?.filter(list => !formData.inclusion_list_ids?.includes(list.id))
                                                            .map(list => (
                                                                <MenuItem key={list.id} value={list.id}>
                                                                    {list.name}
                                                                </MenuItem>
                                                            ))
                                                        }
                                                    </Select>
                                                </div>
                                            </FormControl>

                                            <FormControl fullWidth>
                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', marginBottom: '8px' }}>
                                                    <InputLabel style={{ position: 'relative' }}>Exclusion Lists</InputLabel>
                                                    <Button
                                                        size="small"
                                                        startIcon={isCreatingExclusionList ? 
                                                            <CircularProgress size={16} /> : 
                                                            <Icon.Plus height={16} width={16} />
                                                        }
                                                        onClick={handleCreateExclusionList}
                                                        disabled={!formData.name.trim() || isCreatingExclusionList}
                                                        style={{ 
                                                            opacity: !formData.name.trim() ? 0.6 : 1,
                                                            position: 'relative'
                                                        }}
                                                        title={!formData.name.trim() ? 
                                                            'Enter promotion name before creating a new unit list' : 
                                                            'Create new exclusion list'
                                                        }
                                                    >
                                                        New List
                                                    </Button>
                                                </div>
                                                <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', marginTop: '16px' }}>
                                                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '4px', margin: '8px 0' }}>
                                                        {(formData.exclusion_list_ids || []).map((id) => {
                                                            const list = unitLists?.find(l => l.id === id);
                                                            return (
                                                                <Chip
                                                                    key={id}
                                                                    label={list?.name || id}
                                                                    onDelete={() => {
                                                                        setFormData(prev => ({
                                                                            ...prev,
                                                                            exclusion_list_ids: prev.exclusion_list_ids.filter(listId => listId !== id)
                                                                        }));
                                                                    }}
                                                                    size="small"
                                                                />
                                                            );
                                                        })}
                                                    </div>
                                                    <Select
                                                        value=""
                                                        onChange={(e) => {
                                                            const newValue = e.target.value as number;
                                                            if (newValue) {
                                                                setFormData(prev => ({
                                                                    ...prev,
                                                                    exclusion_list_ids: [...(prev.exclusion_list_ids || []), newValue]
                                                                }));
                                                            }
                                                        }}
                                                        displayEmpty
                                                        renderValue={() => <em>
                                                            {isFetchingUnitLists ? 'Loading...' : 'Select lists to exclude'}
                                                        </em>}
                                                        disabled={isFetchingUnitLists}
                                                    >
                                                        {unitLists
                                                            ?.filter(list => !formData.exclusion_list_ids?.includes(list.id))
                                                            .map(list => (
                                                                <MenuItem key={list.id} value={list.id}>
                                                                    {list.name}
                                                                </MenuItem>
                                                            ))
                                                        }
                                                    </Select>
                                                </div>
                                            </FormControl>
                                        </div>
                                    </div>
                                </AccordionDetails>
                            </Accordion>

                            {/* Discount Settings Section */}
                            <Accordion 
                                expanded={expandedSection === 'discountSettings'}
                                onChange={() => setExpandedSection(expandedSection === 'discountSettings' ? false : 'discountSettings')}
                            >
                                <AccordionSummary 
                                    expandIcon={
                                        <Icon.ChevronDown 
                                            style={chevronStyle(expandedSection === 'discountSettings')}
                                            height={20} 
                                            width={20} 
                                        />
                                    }
                                >
                                    <Typography variant="h6">Discount Settings</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '16px', width: '100%' }}>
                                        <div style={{ display: 'flex', gap: '16px' }}>
                                            <FormControl style={{ minWidth: '200px' }}>
                                                <InputLabel>Blackout Method</InputLabel>
                                                <Select
                                                    value={formData.blackout_method}
                                                    onChange={(e) => setFormData(prev => ({
                                                        ...prev,
                                                        blackout_method: e.target.value as string
                                                    }))}
                                                >
                                                    <MenuItem value="none">None</MenuItem>
                                                    <MenuItem value="gross up">Gross Up</MenuItem>
                                                    <MenuItem value="exclude">Exclude</MenuItem>
                                                    <MenuItem value="include">Include</MenuItem>
                                                </Select>
                                            </FormControl>
                                            <FormControl style={{ minWidth: '200px' }}>
                                                <InputLabel>Discount Type</InputLabel>
                                                <Select
                                                    value={formData.discount_type}
                                                    onChange={(e) => setFormData(prev => ({
                                                        ...prev,
                                                        discount_type: e.target.value as string
                                                    }))}
                                                >
                                                    <MenuItem value="percent">Percent</MenuItem>
                                                    <MenuItem value="dollar">Dollars</MenuItem>
                                                </Select>
                                            </FormControl>
                                            <TextField
                                                label="Discount Value"
                                                type="number"
                                                value={formData.discount_value || ''}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    const numValue = value === '' ? null : parseInt(value, 10);
                                                    const error = validateDiscountValue(numValue);
                                                    
                                                    setDiscountValueError(error);
                                                    setFormData(prev => ({
                                                        ...prev,
                                                        discount_value: numValue
                                                    }));
                                                }}
                                                error={!!discountValueError}
                                                helperText={discountValueError}
                                                required
                                                inputProps={{
                                                    min: 1,
                                                    step: 1
                                                }}
                                                style={{ minWidth: '200px' }}
                                            />
                                        </div>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    </MuiPickersUtilsProvider>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary" disabled={isSaving}>
                        Cancel
                    </Button>
                    <Button
                        onClick={handleSubmit}
                        color="primary"
                        variant="contained"
                        disabled={
                            isSaving ||
                            !formData.name.trim() ||
                            !formData.promo_code.trim() ||
                            !!promoCodeError ||
                            !!discountValueError ||
                            !formData.discount_value ||
                            !isValidActiveDateRange(formData.active_date_start, formData.active_date_end)
                        }
                        startIcon={isSaving ? <CircularProgress size={20} color="inherit" /> : null}
                    >
                        {formMode === 'create' ? 'Create' : 
                         formMode === 'edit' ? 'Save' : 
                         'Copy'}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={approvalDialogOpen}
                onClose={() => setApprovalDialogOpen(false)}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>
                    Update Approval Status
                </DialogTitle>
                <DialogContent>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '16px', paddingTop: '8px' }}>
                        <FormControl fullWidth>
                            <InputLabel>Status</InputLabel>
                            <Select
                                value={approvalStatus}
                                onChange={(e) => setApprovalStatus(e.target.value as string)}
                            >
                                <MenuItem value="approved">Approved</MenuItem>
                                <MenuItem value="pending">Pending</MenuItem>
                                <MenuItem value="rejected">Rejected</MenuItem>
                            </Select>
                        </FormControl>

                        {approvalStatus === 'rejected' && (
                            <TextField
                                label="Rejection Reason"
                                value={rejectionReason}
                                onChange={(e) => setRejectionReason(e.target.value)}
                                multiline
                                rows={3}
                                required
                                fullWidth
                            />
                        )}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setApprovalDialogOpen(false)}
                        color="primary"
                        disabled={isApproving}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleApprovalSubmit}
                        color="primary"
                        variant="contained"
                        disabled={isApproving || (approvalStatus === 'rejected' && !rejectionReason.trim())}
                        startIcon={isApproving ? <CircularProgress size={20} color="inherit" /> : null}
                    >
                        Update
                    </Button>
                </DialogActions>
            </Dialog>
        </Paper>
    );
};
