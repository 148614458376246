import {Tabs} from '@vacasa/react-components-lib';
import React, {useState} from "react";
import {AdminUsers} from "./AdminUsers";
import {ReblOptionsTable} from "./ReblOptions";
import PromoOptions from './PromoOptions';

import {Loading} from "../Common/Loading/Loading";
import {useGetPromotionOptionsQuery, useGetReblOptionsQuery} from "../../store";

export type PromoTable = 'Strategy Types' | 'Promotion Types' | 'Usage Types';


export const Admin:  React.FC = () => {
    const {refetch: refetchReblOptions, data: reblOptionsData, isFetching: isFetchingReblOptions} = useGetReblOptionsQuery();
    const { data: promotionOptions, isLoading: isFetchingPromoOptions, refetch: refetchPromoOptions } = useGetPromotionOptionsQuery();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [selectedOptionTable, setSelectedOptionTable] = useState<string>("Action");
    const [searchValue, setSearchValue] = useState<string>("");
    const [searchPromoValue, setSearchPromoValue] = useState<string>('');
    const [selectedPromoOptionTable, setSelectedPromoOptionTable] = useState<PromoTable>('Strategy Types');

    const tabs = [{
        id: "admin_users",
        label: "Admin Users",
        component: <AdminUsers></AdminUsers>
    }, {
        id: "rebl_options",
        label: "REBL Options",
        component: <ReblOptionsTable
            selectedOptionTable={selectedOptionTable}
            setSelectedOptionTable={setSelectedOptionTable}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            reblOptionsData={reblOptionsData}
            refetchReblOptions={refetchReblOptions}
            isFetchingReblOptionsData={isFetchingReblOptions}
        />
    }, {
        id: "promotion_options",
        label: "Promotion Options",
        component: <PromoOptions
            selectedOptionTable={selectedPromoOptionTable}
            setSelectedOptionTable={setSelectedPromoOptionTable}
            searchValue={searchPromoValue}
            setSearchValue={setSearchPromoValue}
            promotionOptions={promotionOptions}
            isFetchingPromoOptions={isFetchingPromoOptions}
            refetchPromoOptions={refetchPromoOptions}
        />
    }]

    const [selectedTab, setSelectedTab] = useState<number>(0);

    setTimeout(() => {
        setIsLoading(false);
    }, 1000)

    return (
        <div className="tabs-container">
            {isLoading ? <Loading></Loading> :
                <>
                    <Tabs
                        selected={selectedTab}
                        tabs={tabs}
                        onChange={(index) => setSelectedTab(index)}
                    />
                </>
            }
        </div>
    )
}