import React, {useCallback, useEffect, useState} from 'react';
import {IconButton, Paper, Drawer} from '@material-ui/core';
import {Button, Icon, Modal, Tabs, Tooltip} from '@vacasa/react-components-lib';
import {Configuration} from '../../Configuration';
import './Home.scss';
import { format, fromUnixTime } from 'date-fns';
import {
    Admin,
    CohortList,
    DemandInfluenceList,
    PromotionsList,
    Loading,
    ReblRulesetList,
    UnitsTable,
    MissingRates,
    ReblAdmin
} from "../../components";
import {useHistory} from "react-router-dom";
import {Cohort} from "../../types";
import {useGetAllCohortsQuery, useGetCurrentUserQuery, useGetPipelineStatusQuery, useGetUnitLocationOptionsQuery} from "../../store";
import {isEmpty, sortBy} from "lodash";
import {UnitTetherTable} from "../../components/Tables/UnitTether";
import {HolidaysTable} from "../../components/Tables/Holidays";
import {RatesEvolution} from "../../components/Rates/RatesEvolution";
import UnitLists from "../../components/Units/UnitLists";
import {DateLists} from "../../components/DateLists/DateLists";

export const Home: React.FC = () => {
    const search = window.location.search;
    const history = useHistory();
    const params = new URLSearchParams(search);
    const [loading, setLoading] = useState<boolean>(true);
    const tabParam = params.get('tab');

    // Add new state variables
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);
    const [isMinimized, setIsMinimized] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    const [openAdminModal, setOpenAdminModal] = useState<boolean>(false);
    const [openMissingRatesModal, setOpenMissingRatesModal] = useState<boolean>(false);
    const [openReblAdminModal, setOpenReblAdminModal] = useState<boolean>(false);
    const [openUnitListsModal, setOpenUnitListsModal] = useState<boolean>(false);
    const [showNonProdWarning, setShowNonProdWarning] = useState<boolean>(true);
    const [pipelineSuccessful, setPipelineSuccessful] = useState<boolean>(false);
    const [statusMessage, setStatusMessage] = useState<string>("");
    const [loadingStatus, setLoadingStatus] = useState<boolean>(true);
    const [selectedUnitListId, setSelectedUnitListId] = useState<number | null>(null);
    const [openDateListsModal, setOpenDateListsModal] = useState<boolean>(false);
    const [selectedDateListId, setSelectedDateListId] = useState<number | null>(null);
    const [refetchPromotions, setRefetchPromotions] = useState<(() => Promise<any>) | null>(null);
    const [shouldRefreshPromotions, setShouldRefreshPromotions] = useState<boolean>(false);
    const [triggerPromotionRefetch, setTriggerPromotionRefetch] = useState<boolean>(false);

    // TODO: cache this
    const {refetch: refetchCohorts, data: cohortInfo, isFetching: isFetchingCohorts} = useGetAllCohortsQuery();
    const {data: currentUser, isFetching: isFetchingCurrentUser} = useGetCurrentUserQuery();
    const {data: locationOptions, isFetching: isFetchingLocationOptions} = useGetUnitLocationOptionsQuery();
    const {data: pipelineStatus, isFetching: isFetchingPipelineStatus, refetch: refetchPipelineStatus} = useGetPipelineStatusQuery();

    const [cohortData, setCohortData] = useState<Cohort[]>([]);

    function onUpdateCohort(updatedCohort: Cohort) {
        const updatedCohorts = cohortData.map(
            cohort => {
                if (cohort.id === updatedCohort.id) {
                    return updatedCohort
                } else {return cohort}
            }
        )
        setCohortData(updatedCohorts)
    }

    const formatDate = (unixTime: number): string => format(fromUnixTime(unixTime), 'yyyy-MM-dd HH:mm:SS');

    useEffect(() => {
        // a way to ensure the refetch function is populated
        console.log('Trigger:', triggerPromotionRefetch, 'Refetch fn:', refetchPromotions);
        if (refetchPromotions && triggerPromotionRefetch) {
            console.log("Triggering promotion refetch");
            // Handle the Promise properly
            refetchPromotions()
                .then(() => console.log("Refetch complete"))
                .catch(err => console.error("Refetch failed:", err))
                .finally(() => setTriggerPromotionRefetch(false));
        }
    }, [triggerPromotionRefetch, refetchPromotions]);

    useEffect(() => {
        if(!isFetchingPipelineStatus) {
            console.log(pipelineStatus)
            if (
                !!pipelineStatus &&
                pipelineStatus["ns_ui"]["latest_status"] === "success" &&
                pipelineStatus["ns_pipeline"]["latest_status"] === "success"
            ) {
                setPipelineSuccessful(true)
                setStatusMessage(`Data updated ${formatDate(pipelineStatus["ns_ui"]["latest_datetime"])}`)
            }
            else {
                setPipelineSuccessful(false)

                if (!pipelineStatus) {
                    setStatusMessage("Error while fetching pipeline status")
                }
                else {
                    let msg = `NS pipeline running ${pipelineStatus["ns_pipeline"]["latest_task"]} at ${formatDate(pipelineStatus["ns_pipeline"]["latest_datetime"])}`;
                    if (pipelineStatus["ns_pipeline"]["latest_status"] === "running") {
                        setStatusMessage(msg)
                    } else {
                        msg = `Latest pipeline status is ${pipelineStatus["ns_pipeline"]["latest_status"]};` + msg;
                        setStatusMessage(msg)
                    }
                }
            }

            setTimeout(() => {
                setLoadingStatus(false);
            }, 1000) // When users request a refetch the cached response is too immediate, so, make it look like it's doing something
        }
    }, [pipelineStatus, isFetchingPipelineStatus]);

    const updateStatus = () => {
        setLoadingStatus(true);
        if (!isFetchingPipelineStatus) refetchPipelineStatus();
    }

    // setTimeout(() => {refetchPipelineStatus()}, 60_000)
    const editUnitListInModal = (listId: number) => {
        setSelectedUnitListId(listId);
        setOpenUnitListsModal(true);
    };

    const editDateListInModal = (listId: number) => {
        setSelectedDateListId(listId);
        setOpenDateListsModal(true);
    };

    // Add resize listener
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 1024);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Add new useEffect for initial drawer state
    useEffect(() => {
        // Start with drawer open
        setIsMinimized(false);
        
        // Close drawer after 2 seconds if not hovered
        const timer = setTimeout(() => {
            if (!isHovered) {
                setIsMinimized(true);
            }
        }, 2000);

        return () => clearTimeout(timer);
    }, []); // Only run on mount

    // Memoize the refetch function setter to avoid serialization issues
    const setRefetchPromotionsCallback = useCallback((fn: () => Promise<any>) => {
        setRefetchPromotions(() => fn);
    }, []);

    const tabs = [
        {
            id: "strategic_cohorts",
            label: "Cohorts",
            icon: <Icon.Globe height={20} width={20} />,
            component: <CohortList
                cohortData={cohortData}
                isFetchingCohorts={isFetchingCohorts || isFetchingCurrentUser}
                onRefresh={refetchCohorts}
                onUpdateCohort={onUpdateCohort}
                currentUser={currentUser}
                refetchCohorts={refetchCohorts}
            />
        },
        {
            id: "demand_influences",
            label: "Demand Influences",
            icon: <Icon.TrendingUp height={20} width={20} />,
            component: <DemandInfluenceList
                cohortData={cohortData}
                isFetchingCohorts={isFetchingCohorts || isFetchingCurrentUser}
                currentUser={currentUser}
            />
        },
        {
            id: "unit_assigner",
            label: "Units",
            icon: <Icon.Home height={20} width={20} />,
            component: <UnitsTable
                isFetchingCohorts={isFetchingCohorts || isFetchingCurrentUser}
                cohortData={cohortData}
                currentUser={currentUser}
            />
        },
        {
            id: "rebl",
            label: "REBL",
            icon: <Icon.Settings height={20} width={20} />,
            component: <ReblRulesetList
                isFetchingCohorts={isFetchingCohorts || isFetchingCurrentUser}
                isFetchingLocationOptions={isFetchingLocationOptions}
                cohortData={cohortData}
                currentUser={currentUser}
                locationOptions={locationOptions}
            />
        },
        {
            id: "tethering",
            label: "Tethering",
            icon: <Icon.Link height={20} width={20} />,
            component: <UnitTetherTable/>
        },
        {
            id: "holidays",
            label: "Holidays",
            icon: <Icon.Calendar height={20} width={20} />,
            component: <HolidaysTable
                isFetchingCohorts={isFetchingCohorts || isFetchingCurrentUser}
                cohortData={cohortData}
                currentUser={currentUser}
                locationOptions={locationOptions}
                isFetchingLocationOptions={isFetchingLocationOptions}
            />
        },
        {
            id: "promotions",
            label: "Promotions",
            icon: <Icon.Tag height={20} width={20} />,
            component: <PromotionsList
                currentUser={currentUser}
                openUnitListModal={editUnitListInModal}
                openDateListModal={editDateListInModal}
                setRefetchPromotions={setRefetchPromotionsCallback}
            />
        },
        {
            id: "rates",
            label: "Rates Viewer",
            icon: <Icon.MoneyTag height={20} width={20} />,
            component: <RatesEvolution cohorts={cohortData.filter(c => c.unit_count > 0)}/>
        }
    ];

    const [selectedTab, setSelectedTab] = useState<number>(
        Math.max(tabParam ? tabs.findIndex(idx => idx.id === tabParam) : 0, 0)
    );

    const openModal = (name: string) => {
        setOpenAdminModal(name === "admin");
        setOpenMissingRatesModal(name === "missing_rates");
        setOpenReblAdminModal(name === "rebl_admin");
        setOpenUnitListsModal(name === "unit_lists");
        setOpenDateListsModal(name === "date_lists");
    }

    // // TODO: Unused, but should consider setting filters from search parameters....
    // useEffect(() => {
    //     if(cohortParam) setSelectedCohortID(cohortParam);
    // }, [cohortParam])

    useEffect(() => {
        if (!isEmpty(cohortInfo)) setCohortData(sortBy(cohortInfo, (cohort) => cohort.name));
    }, [cohortInfo, isFetchingCohorts])

    useEffect(() => {
        if (selectedTab !== null && selectedTab >= 0) {
            let newParams = new URLSearchParams();
            newParams.set("tab", tabs[selectedTab]["id"])
            history.push({
                pathname: window.location.pathname,
                search: "?" + newParams.toString()
            })

            setLoading(false);
        }
    }, [selectedTab])

    const handleUnitListModalClose = () => {
        setOpenUnitListsModal(false);
        setSelectedUnitListId(null);
        setTriggerPromotionRefetch(shouldRefreshPromotions);
        setShouldRefreshPromotions(false);
    };

    const handleDateListModalClose = () => {
        setOpenDateListsModal(false);
        setSelectedDateListId(null);
        setTriggerPromotionRefetch(shouldRefreshPromotions);
        setShouldRefreshPromotions(false);
    };

    return (
        <Paper elevation={3} className="home">
            {!Configuration.isProduction && !Configuration.isLocal && showNonProdWarning &&
                <span className={"lower-env-banner"}>
                    <Tooltip message={"hide banner"} onClick={() => setShowNonProdWarning(false)} className={"pointer"}>
                        <Icon.XCircle height={24} width={24}/>
                    </Tooltip>
                    <h1>This is a training environment -- Changes will not be permanent</h1>
                </span>
            }

            <div className="layout-container">
                {isMobile && (
                    <IconButton
                        className="menu-button"
                        onClick={() => setSidebarOpen(!sidebarOpen)}
                    >
                        <Icon.Menu height={24} width={24} />
                    </IconButton>
                )}

                <Drawer
                    variant={isMobile ? "temporary" : "permanent"}
                    open={isMobile ? sidebarOpen : true}
                    onClose={() => setSidebarOpen(false)}
                    className={`sidebar ${isMinimized ? 'minimized' : ''}`}
                    classes={{
                        paper: 'sidebar-paper'
                    }}
                    onMouseEnter={() => {
                        setIsHovered(true);
                        setIsMinimized(false);
                    }}
                    onMouseLeave={() => {
                        setIsHovered(false);
                        setIsMinimized(true);
                    }}
                >
                    <div className="sidebar-content">
                        {!isMobile && (
                            <IconButton
                                className="minimize-button"
                                onClick={() => setIsMinimized(!isMinimized)}
                            >
                                {isMinimized ?
                                    <Icon.ChevronsRight height={24} width={24} /> :
                                    <Icon.ChevronsLeft height={24} width={24} />
                                }
                            </IconButton>
                        )}
                        <br/>

                        {tabs.map((tab, index) => (
                            <Tooltip
                                key={tab.id}
                                message={isMinimized ? tab.label : ""}
                                placement="right"
                            >
                                <div
                                    className={`sidebar-item ${selectedTab === index ? 'selected' : ''}`}
                                    onClick={() => {
                                        setSelectedTab(index);
                                        if (isMobile) setSidebarOpen(false);
                                    }}
                                >
                                    <span className="sidebar-item-icon">
                                        {tab.icon}
                                    </span>
                                    <span className="sidebar-item-label">
                                        {tab.label}
                                    </span>
                                </div>
                            </Tooltip>
                        ))}
                    </div>
                </Drawer>

                <div className="main-content">
                    <div className="content-header">
                        <h1 className="content-title">
                            {tabs[selectedTab]?.icon}
                            <span>{tabs[selectedTab]?.label}</span>
                        </h1>
                        <div className="action-buttons">
                            {currentUser?.admin && (
                                <Button variant="secondary" onClick={() => {openModal("admin")}}>
                                    Open Admin
                                </Button>
                            )}
                            {selectedTab === 2 && (
                                <Button variant="primary" onClick={() => {openModal("missing_rates")}}>
                                    <Icon.Search height={16} width={16}/>
                                    Missing Rates
                                </Button>
                            )}
                            {selectedTab === 3 && currentUser?.approver && (
                                <Button variant="primary" onClick={() => {openModal("rebl_admin")}}>
                                    <Icon.Settings height={16} width={16}/>
                                    REBL Adjustments
                                </Button>
                            )}
                            {selectedTab === 6 && (
                                <>
                                    <Button variant="primary" onClick={() => {openModal("unit_lists")}}>
                                        <Icon.Settings height={16} width={16}/>
                                        Unit Lists
                                    </Button>
                                    <Button variant="primary" onClick={() => {openModal("date_lists")}}>
                                        <Icon.Calendar height={16} width={16}/>
                                        Date Lists
                                    </Button>
                                </>
                            )}
                        </div>
                    </div>

                    {loading ? <Loading /> : tabs[selectedTab]?.component}
                </div>
            </div>
            <Modal
                canExit={true}
                setShowModal={setOpenAdminModal}
                showModal={openAdminModal}
                size='large'
            >
                <Admin></Admin>
            </Modal>
            <Modal
                canExit={true}
                setShowModal={setOpenMissingRatesModal}
                showModal={openMissingRatesModal}
                size='medium'
            >
                <MissingRates/>
            </Modal>
            <Modal
                canExit={true}
                setShowModal={setOpenReblAdminModal}
                showModal={openReblAdminModal}
                size='medium'
            >
                <ReblAdmin/>
            </Modal>
            <Modal
                canExit={false}
                setShowModal={setOpenUnitListsModal}
                showModal={openUnitListsModal}
                size='large'
            >
                <div style={{position: 'relative'}}>
                    <IconButton
                        style={{
                            position: 'absolute',
                            right: '8px',
                            top: '8px',
                            zIndex: 1,
                        }}
                        onClick={handleUnitListModalClose}
                        size="small"
                    >
                        <Icon.X height={20} width={20}/>
                    </IconButton>
                    <UnitLists
                        locationOptions={locationOptions}
                        isFetchingLocationOptions={isFetchingLocationOptions}
                        initialSelectedListId={selectedUnitListId}
                        cohortData={cohortData}
                        currentUser={currentUser}
                        onClearSelection={() => setSelectedUnitListId(null)}
                        setShouldRefreshPromotions={setShouldRefreshPromotions}
                    />
                </div>
            </Modal>
            <Modal
                canExit={false}
                setShowModal={setOpenDateListsModal}
                showModal={openDateListsModal}
                size='large'
            >
                <div style={{position: 'relative'}}>
                    <IconButton
                        style={{
                            position: 'absolute',
                            right: '8px',
                            top: '8px',
                            zIndex: 1,
                        }}
                        onClick={handleDateListModalClose}
                        size="small"
                    >
                        <Icon.X height={20} width={20}/>
                    </IconButton>
                    <DateLists
                        initialSelectedListId={selectedDateListId}
                        currentUser={currentUser}
                        onClearSelection={() => setSelectedDateListId(null)}
                        setShouldRefreshPromotions={setShouldRefreshPromotions}
                    />
                </div>
            </Modal>
            <footer>
                {loadingStatus ?
                    <>
                        <Icon.Loader className={"spinning-icon"} height={20} width={20}/>
                        Fetching status of Northstar Pipeline...
                    </>
                    :
                    <>
                    {pipelineSuccessful ?
                        <Icon.CheckSquare height={20} width={20}/> :
                        <Icon.AlertTriangle height={20} width={20}/>
                    }
                    {statusMessage} {!isFetchingPipelineStatus && !loadingStatus &&
                        <Icon.RefreshCCW height={16} width={16} onClick={updateStatus}/>
                    }
                    </>
                }
            </footer>
        </Paper>
    );
};