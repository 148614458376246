import { DateList } from "../types";
import {apiDateList} from "./api";
import {
    createSlice,
    PayloadAction,
    createEntityAdapter,
    EntityState
} from "@reduxjs/toolkit";
import {AppState} from "./store";


const dateListAdapter = createEntityAdapter<DateList>({
    selectId: (dl) => dl.id,
    sortComparer: (a, b) => a.id - b.id
});

const initialState: EntityState<DateList> = dateListAdapter.getInitialState();

export const dateListApi = apiDateList.injectEndpoints({
    endpoints: (builder) => ({
        getAllDateLists: builder.query<DateList[], void>({
            query: () => "date_lists",
        }),
        getDateList: builder.mutation<number[], number>({
            query: (id: number) => `date_lists/${id}`,
        }),
        addDateList: builder.mutation<number, DateList>({
            query: (body) => ({
                url: "date_lists",
                method: "POST",
                body: JSON.stringify((body))
            })
        }),
        updateDateList: builder.mutation<void, { id: number; data: Partial<DateList> }>({
            query: ({id, data}) => ({
                url: `date_lists/${id}`,
                method: "PUT",
                body: JSON.stringify(data),
            }),
        }),
        linkDatesToParent:builder.mutation<void, {id: number; data: string[]}>({
            query: ({id, data}) => ({
                url: `link_dates_to_list/${id}`,
                method: "POST",
                body: JSON.stringify(data),
            })
        }),
        refreshDateList:builder.mutation<void, {id: number; data: Partial<DateList>}>({
            query: ({id, data}) => ({
                url: `refresh_linked_dates/${id}`,
                method: "POST",
                body: JSON.stringify(data),
            })
        }),
    }),
    overrideExisting: false,
});

export const dateListSlice = createSlice({
    name: "dateList",
    initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        merge: (state, action: PayloadAction<DateList[]>) => {
            dateListAdapter.upsertMany(state, action.payload);
        }
    },
    extraReducers: (builder) => {
    }
});

export const {
    useGetAllDateListsQuery,
    useGetDateListMutation,
    useAddDateListMutation,
    useUpdateDateListMutation,
    useRefreshDateListMutation,
    useLinkDatesToParentMutation,
} = dateListApi;

export const dateListSelector = (state: AppState) => state.dateList;

export default dateListSlice.reducer;