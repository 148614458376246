import {
    CohortAssignment,
    Unit,
    UnmappedResponse,
    StrategicCohort,
    UnitQuality,
    MissingRatesReason,
    UnitList,
} from "../types";
import {apiUnit} from "./api";
import {
    createSlice,
    PayloadAction,
    createEntityAdapter,
    EntityState
} from "@reduxjs/toolkit";
import {AppState} from "./store";


const unitAdapter = createEntityAdapter<Unit>({
    selectId: (unit) => unit.id,
    sortComparer: (a, b) => a.id - b.id
});

const initialState: EntityState<Unit> = unitAdapter.getInitialState();

export const unitApi = apiUnit.injectEndpoints({
    endpoints: (builder) => ({
        getAllUnits: builder.query<UnmappedResponse, void>({
            query: () => "units",
            providesTags: [{type: "Units", id: "UnitList"}]
        }),
        getAllUnitLists: builder.query<UnitList[], void>({
            query: () => "unit_lists",
            //providesTags: [{type: "Units", id: "UnitList"}]
        }),
        getUnitList: builder.mutation<number[], number>({
            query: (id: number) => `unit_lists/${id}`,
            //providesTags: [{type: "UnitLists", id: "UnitList"}]
        }),
        addUnitList: builder.mutation<number, UnitList>({
            query: (body) => ({
                url: "unit_lists",
                method: "POST",
                body: JSON.stringify((body))
            })
        }),
        updateUnitList: builder.mutation<void, { id: number; data: Partial<UnitList> }>({
            query: ({id, data}) => ({
                url: `unit_lists/${id}`,
                method: "PUT",
                body: JSON.stringify(data),
            }),
        }),
        linkUnitsToParent:builder.mutation<void, {id: number; data: number[]}>({
            query: ({id, data}) => ({
                url: `link_units_to_list/${id}`,
                method: "POST",
                body: JSON.stringify(data),
            })
        }),
        refreshUnitList:builder.mutation<void, {id: number; data: Partial<UnitList>}>({
            query: ({id, data}) => ({
                url: `refresh_linked_units/${id}`,
                method: "POST",
                body: JSON.stringify(data),
            })
        }),
        getSuggestedCohorts: builder.query<StrategicCohort[], {
            id: number,
            super_unit: boolean
        }>({
            query: ({id, super_unit}) => `suggested_cohorts/${id.toString()}/${super_unit.toString()}`,
            providesTags: [{type: "Units", id: "StrategicCohortList"}]
        }),
        addCohortAssignment: builder.mutation<void, CohortAssignment>({
            query: (body) => ({
                url: `assign_cohort`,
                method: "POST",
                body: JSON.stringify(body)
            }),
        }),
        removeCohortAssignment: builder.mutation<void, {
            id: number,
            super_unit: boolean
        }>({
           query: ({id, super_unit}) => ({
               url: `assign_cohort/${id}/${super_unit}`,
               method: "DELETE"
           })
        }),
        postUnitQualityScore: builder.mutation<void, UnitQuality>({
            query: (body) => ({
                url: `unit_quality`,
                method: "POST",
                body: JSON.stringify(body)
            }),
        }),
        removeQualityScore: builder.mutation<void, number>({
            query: (id: number) => ({
                url: `unit_quality/${id}`,
                method: "DELETE"
            })
        }),
        excludeUnit: builder.mutation<void, number>({
            query: (unit_id: number) => ({
                url: `exclude_unit/${unit_id.toString()}`,
                method: "POST"
            })
        }),
        getMissingRatesReason: builder.mutation<MissingRatesReason[], number>({
            query: (unit_id: number) => ({
                url: `missing_rate_unit/${unit_id.toString()}`,
                method: "GET"
            })
        }),
    }),
    overrideExisting: false,
});

export const unitSlice = createSlice({
    name: "units",
    initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        merge: (state, action: PayloadAction<Unit[]>) => {
            unitAdapter.upsertMany(state, action.payload);
        }
    },
    extraReducers: (builder) => {
        // we'll match on the async action or the manual increment being that both have a payload of type `number`
        // builder.addMatcher(
        //     unitApi.endpoints.getAllUnits.matchFulfilled, (state, {payload}) => {
        //     unitAdapter.upsertMany(state, payload);
        // })
    }
});

export const {
    useGetAllUnitsQuery,
    useGetAllUnitListsQuery,
    useGetUnitListMutation,
    useAddUnitListMutation,
    useUpdateUnitListMutation,
    useRefreshUnitListMutation,
    useLinkUnitsToParentMutation,
    useGetSuggestedCohortsQuery,
    useAddCohortAssignmentMutation,
    useRemoveCohortAssignmentMutation,
    usePostUnitQualityScoreMutation,
    useRemoveQualityScoreMutation,
    useExcludeUnitMutation,
    useGetMissingRatesReasonMutation,
} = unitApi;

export const unitSelector = (state: AppState) => state.units;

export default unitSlice.reducer;