import {Promotion} from "../types";
import {apiPromotions} from "./api";
import {
    createSlice,
    PayloadAction,
    createEntityAdapter,
    EntityState
} from "@reduxjs/toolkit";
import {AppState} from "./store";


export interface PromotionOptions {
    strategy_types: PromotionOption[],
    promotion_types: PromotionOption[],
    usage_types: PromotionOption[],
}

export interface PromotionOption {
    id: number;
    name: string;

    active?: boolean;

    vacasa?: boolean;
    vrbo?: boolean;
    airbnb?: boolean;
    bdc?: boolean;
    mybookingpal?: boolean;
    expedia?: boolean;

    option_type?: string;
}

export interface CreatePromotionOptionRequest {
    name: string;
    active?: boolean;

    vacasa?: boolean;
    vrbo?: boolean;
    airbnb?: boolean;
    bdc?: boolean;
    mybookingpal?: boolean;
    expedia?: boolean;

    option_type?: string;
}

export interface UpdatePromotionOptionRequest extends CreatePromotionOptionRequest {
    id: number;
}

const promotionsAdapter = createEntityAdapter<any>({
    selectId: (promotion) => promotion.id,
    sortComparer: (a, b) => a.name.localeCompare(b.name)
});

const initialState: EntityState<any> = promotionsAdapter.getInitialState();
export const promotionsApi = apiPromotions.injectEndpoints({
    endpoints: (builder) => ({
        getAllPromotions: builder.query<Promotion[], void>({
            query: () => "promotions",
            // providesTags: [{type: "Promotions", id: "promotionsList"}]
        }),
        addPromotion: builder.mutation<Promotion, Partial<Promotion> >({
            query: (body) => ({
                url: `promotions`,
                method: "POST",
                body: JSON.stringify(body)
            }),
            // invalidatesTags: [{type: 'Promotions', id: 'promotionsList'}]
        }),
        updatePromotion: builder.mutation<Promotion, { id: number; data: Partial<Promotion> }>({
            query: ({id, data}) => ({
                url: `promotions/${id}`,
                method: "PUT",
                body: JSON.stringify(data),
            }),
        }),
        getPromotionOptions: builder.query<PromotionOptions, void>({
            query: () => ({
                url: 'promotion_options',
                method: 'GET',
            }),
            // providesTags: ['PromotionOptions'],
        }),

        createPromotionOption: builder.mutation<PromotionOption, CreatePromotionOptionRequest>({
            query: (body) => ({
                url: 'promotion_options',
                method: 'POST',
                body: JSON.stringify(body),
            }),
            // invalidatesTags: ['PromotionOptions'],
        }),
        updatePromotionOption: builder.mutation<PromotionOption, UpdatePromotionOptionRequest>({
            query: (body) => ({
                url: `promotion_options/${body.id}`,
                method: 'PUT',
                body: JSON.stringify(body),
            }),
            // invalidatesTags: ['PromotionOptions'],
        }),
    }),
    overrideExisting: false,
});

export const promotionsSlice = createSlice({
    name: "promotions",
    initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        merge: (state, action: PayloadAction<Promotion[]>) => {
            promotionsAdapter.upsertMany(state, action.payload);
        }
    },
    extraReducers: (builder) => {
        // we'll match on the async action or the manual increment being that both have a payload of type `number`
        builder.addMatcher(
            promotionsApi.endpoints.getAllPromotions.matchFulfilled, (state, {payload}) => {
            promotionsAdapter.upsertMany(state, payload);
        })
    }
});

export const {
    useGetAllPromotionsQuery,
    useAddPromotionMutation,
    useUpdatePromotionMutation,
    useGetPromotionOptionsQuery,
    useCreatePromotionOptionMutation,
    useUpdatePromotionOptionMutation,
} = promotionsApi;

export const promotionsSelector = (state: AppState) => state.promotions;

export default promotionsSlice.reducer;