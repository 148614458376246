import {DemandInfluence, DemandFactor} from "../types";
import {apiDemandInfluence} from "./api";
import {
    createSlice,
    PayloadAction,
    createEntityAdapter,
    EntityState
} from "@reduxjs/toolkit";
import {AppState} from "./store";


const demandInfluenceAdapter = createEntityAdapter<DemandInfluence>({
    selectId: (demandInfluence) => demandInfluence.id,
    sortComparer: (a, b) => a.title.localeCompare(b.title)
});

const initialState: EntityState<DemandInfluence> = demandInfluenceAdapter.getInitialState();

export const demandInfluenceApi = apiDemandInfluence.injectEndpoints({
    endpoints: (builder) => ({
        getAllDemandInfluences: builder.query<DemandInfluence[], void>({
            query: () => "demand_influences",
            providesTags: [{type: "DemandInfluences", id: "demandInfluenceList"}]
        }),
        getDemandInfluence: builder.query({
            query: () => {
                return `demand_influences`;
            },
            providesTags: [{type: "DemandInfluences", id: "demandInfluences"}]
        }),
        addDemandInfluence: builder.mutation<DemandInfluence, Partial<DemandInfluence> >({
            query: (body) => ({
                url: `demand_influences`,
                method: "POST",
                body: JSON.stringify(body)
            }),
            invalidatesTags: [{type: 'DemandInfluences', id: 'demandInfluenceList'}]
        }),
        updateDemandInfluence: builder.mutation<DemandInfluence, { id: number; data: Partial<DemandInfluence> }>({
            query: ({id, data}) => ({
                url: `demand_influences/${id}`,
                method: "PUT",
                body: JSON.stringify(data),
            }),
            invalidatesTags: [{type: "DemandInfluences", id: "demandInfluenceList"}, {type: "DemandInfluences", id: "demandInfluence"}]
        }),
        getDemandFactor: builder.query<DemandFactor[], {
            cohort_id: string,
            start_date: string,
            end_date: string,
            factor_type: string
        }>({
            query: ({cohort_id, start_date, end_date, factor_type}) =>
                `demand_factors?cohort_id=${cohort_id}&start_date=${start_date}&end_date=${end_date}&factor_type=${factor_type}`,
        })
    }),
    overrideExisting: false,
});

export const demandInfluenceSlice = createSlice({
    name: "demandInfluences",
    initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        merge: (state, action: PayloadAction<DemandInfluence[]>) => {
            demandInfluenceAdapter.upsertMany(state, action.payload);
        }
    },
    extraReducers: (builder) => {
        // we'll match on the async action or the manual increment being that both have a payload of type `number`
        builder.addMatcher(
            demandInfluenceApi.endpoints.getAllDemandInfluences.matchFulfilled, (state, {payload}) => {
            demandInfluenceAdapter.upsertMany(state, payload);
        })
    }
});

export const {
    useGetAllDemandInfluencesQuery,
    useGetDemandInfluenceQuery,
    useAddDemandInfluenceMutation,
    useUpdateDemandInfluenceMutation,
    useGetDemandFactorQuery,
} = demandInfluenceApi;

export const demandInfluenceSelector = (state: AppState) => state.demandInfluences;

export default demandInfluenceSlice.reducer;